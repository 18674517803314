import { useEffect, useState } from 'react';
import i18n from '../../i18n';
import * as urlConstants from '../../utils/constants/urlConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const usePreferredLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const prefilledPreferredLanguage = queryParams.get(urlConstants.PREFERRED_LANGUAGE);

    if (prefilledPreferredLanguage) {
      const languageMatch = translationConstants.LANGUAGE_OPTIONS.find(
        (option) =>
          option.value.toLowerCase() === prefilledPreferredLanguage.toLowerCase() ||
          option.value.startsWith(prefilledPreferredLanguage.toLowerCase().replace('_', '-'))
      );

      if (languageMatch) {
        i18n.changeLanguage(languageMatch.value).then(() => {
          setCurrentLanguage(languageMatch.value);
        });
      }
    }
  }, []);

  return currentLanguage;
};
